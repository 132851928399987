import { useService } from "@xstate/react";
import { FieldGroup } from "@yamsafer/formiks";
import { Box, Button, Text } from "@yamsafer/styleguide";
import { Form, Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import SocialOptions from "../components/SocialOptions";
import BlockUi from "@yamsafer/block-ui";

const PasswordLessEmailFields = () => (
  <>
    <FieldGroup
      name="email"
      type="email"
      autoFocus
      label="Email Address"
      data-testid="passwordless-email-field"
      placeholder="example@website.com"
    />
    <Text color="primary">
      <FormattedMessage
        id="sendEmailNote"
        defaultMessage="You will receive an email with a verification code to sign in."
      />
    </Text>
  </>
);

const PasswordLessSmsFields = () => (
  <>
    <FieldGroup
      type="tel"
      autoFocus
      name="phoneNumber"
      label="Phone Number"
      data-testid="passwordless-phone-field"
      placeholder="+972 568 600 526"
    />
    <Text color="primary">
      <FormattedMessage
        id="sendSmsNote"
        defaultMessage="You will receive an sms with a verification code to sign in."
      />
    </Text>
  </>
);

const PasswordLessCodeForm = ({ onSubmit }) => (
  <Formik
    validateOnBlur
    onSubmit={onSubmit}
    initialValues={{
      code: ""
    }}
    render={({}) => (
      <Form>
        <FieldGroup
          name="code"
          placeholder="XXXXX"
          label="Verification Code"
          data-testid="passwordless-code-field"
        />
        <Button
          mx={0}
          width="100%"
          type="submit"
          fontSize={2}
          variant="secondary"
          data-testid="passwordless-code-submit-btn"
        >
          <FormattedMessage
            id="verifyAccount"
            defaultMessage="Verify Account"
          />
        </Button>
      </Form>
    )}
  />
);

const PasswordLessStartForm = ({ onSubmit, connection }) => (
  <Formik
    validateOnBlur
    onSubmit={onSubmit}
    initialValues={
      connection === "sms"
        ? {
            phoneNumber: ""
          }
        : {
            email: ""
          }
    }
    render={({}) => (
      <Form>
        {connection === "sms" && <PasswordLessSmsFields />}
        {connection === "email" && <PasswordLessEmailFields />}
        <br />
        <Text color="primary">
          <FormattedMessage
            id="acceptTermsMessage"
            defaultMessage="By signing in, I agree with Yamsafer’s Terms of Use and Privacy Policy"
          />
        </Text>
        <Button
          mx={0}
          width="100%"
          type="submit"
          fontSize={2}
          variant="secondary"
          data-testid="passwordless-submit-btn"
        >
          {connection === "email" && (
            <FormattedMessage
              id="sendMeAnEmail"
              defaultMessage="Send me and email"
            />
          )}

          {connection === "sms" && (
            <FormattedMessage
              id="sendMeAnEmail"
              defaultMessage="Send me an sms"
            />
          )}
        </Button>
      </Form>
    )}
  />
);

const SwitchConnectionButton = ({ connection, onClick }) => (
  <Button
    width="100%"
    onClick={onClick}
    variant="transparent"
    data-testid="passwordless-switch-connection-btn"
  >
    {connection === "sms" && (
      <FormattedMessage id="useEmail" defaultMessage="Use Email Address?" />
    )}
    {connection === "email" && (
      <FormattedMessage id="usePhone" defaultMessage="Use Phone Number?" />
    )}
  </Button>
);

const PasswordLessLogin = ({
  connection,
  onSwitchConnection,
  onSocial,
  service
}) => {
  const [state, send] = useService(service);

  return (
    <Box>
      {state.matches("passwordless.inputEmailOrPhone") ||
      state.matches("passwordless.submittingEmailOrPhone") ? (
        <BlockUi
          blocking={state.matches("passwordless.submittingEmailOrPhone")}
        >
          <PasswordLessStartForm
            connection={connection}
            onSubmit={values => {
              console.log(values);
              send("submit", values);
            }}
          />
        </BlockUi>
      ) : state.matches("passwordless.inputCode") ||
        state.matches("passwordless.submittingVerificationCode") ? (
        <BlockUi
          blocking={state.matches("passwordless.submittingVerificationCode")}
        >
          <PasswordLessCodeForm onSubmit={values => send("submit", values)} />
        </BlockUi>
      ) : null}
      <Box as="hr" m={0} />
      <SwitchConnectionButton
        connection={connection}
        onClick={e =>
          onSwitchConnection({
            from: connection,
            to: connection === "sms" ? "email" : "sms"
          })
        }
      />
      <Box as="hr" mt={0} />
      <SocialOptions onClick={onSocial} />
    </Box>
  );
};

export default PasswordLessLogin;
