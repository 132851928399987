if (typeof window === "undefined") {
  /**
   * Settings exposed to the server.
   */

  module.exports = {
    AUTH0_CLIENT_ID: "g40XQzID0QQsGvqnjrptaLdmH4MxmWMf",
    AUTH0_CLIENT_SECRET:
      "KTyMczKrV9eZAYx2JwljXGJVfyRT1w9fSkSzLxF5J3TnnqFnGg05tXM5BiMGBR-S",
    AUTH0_SCOPE: "openid",
    AUTH0_DOMAIN: "y-p.eu.auth0.com",
    REDIRECT_URI: "https://b2c-auth-cookie-domain-v2.yamsafer.com/login",
    POST_LOGOUT_REDIRECT_URI: "http://localhost:3000",
    SESSION_COOKIE_SECRET: "823103d6a8241bc49695a55657c32496"
    // SESSION_COOKIE_LIFETIME: 7200
  };
} else {
  /**
   * Settings exposed to the client.
   */
  module.exports = {
    AUTH0_CLIENT_ID: "g40XQzID0QQsGvqnjrptaLdmH4MxmWMf",
    AUTH0_SCOPE: "openid",
    AUTH0_DOMAIN: "y-p.eu.auth0.com",
    REDIRECT_URI: "https://b2c-auth-cookie-domain-v2.yamsafer.com/login",
    POST_LOGOUT_REDIRECT_URI: "http://localhost:3000"
  };
}
