import BtnGroup from "@yamsafer/btn-group";
import { Button, Flex, Heading } from "@yamsafer/styleguide";
import React from "react";
import { FormattedMessage } from "react-intl";
import SocialOptions from "../components/SocialOptions";

const InitialScreen = ({ onSocial, onPasswordless }) => (
  <Flex alignItems="center" justifyContent="center" flexDirection="column">
    <Heading as="h1">
      <FormattedMessage
        id="signUpOrLoginTitle"
        defaultMessage="Sign up or Log in"
      />
    </Heading>
    <Heading as="h3" textAlign="center">
      <FormattedMessage
        id="signUpOrLoginDefaultMessage"
        defaultMessage="Access member only deals, easily manage bookings, earn Cashback rewards, and more!"
      />
    </Heading>

    <SocialOptions onClick={onSocial} />

    <BtnGroup borderColor="secondary" width="100%" mt={2}>
      <Button
        height={40}
        width={1 / 2}
        variant="transparent"
        fontSize="1.333333333rem"
        onClick={e => onPasswordless("sms")}
      >
        <FormattedMessage id="sms" defaultMessage="Phone" />
      </Button>
      <Button
        height={40}
        width={1 / 2}
        variant="transparent"
        fontSize="1.333333333rem"
        onClick={e => onPasswordless("email")}
      >
        <FormattedMessage id="email" defaultMessage="Email" />
      </Button>
    </BtnGroup>
  </Flex>
);

export default InitialScreen;
