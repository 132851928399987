import { assign, Machine } from "xstate";
import auth0Client from "../client";
import { setCookie } from "nookies";

const createPasswordlessStageService = stage => (context, event) =>
  new Promise((resolve, reject) => {
    const { email, connection, phoneNumber, verificationCode } = context;
    const payload = Object.assign(
      {
        connection,
        send: "code"
      },
      verificationCode && { verificationCode },
      connection === "sms" && { phoneNumber },
      connection === "email" && { email }
    );
    auth0Client[stage](payload, (err, res) =>
      err ? reject(err) : resolve(res)
    );
  });

export const config = {
  services: {
    passwordLessStart: createPasswordlessStageService("passwordlessStart"),
    passwordLessLogin: createPasswordlessStageService("passwordlessLogin"),
    socialLogin: (_, { connection }) => auth0Client.authorize({ connection })
  },
  actions: {
    setError: assign({
      error: (_context, event) => event
    }),
    setCode: assign({
      error: null,
      verificationCode: (_context, event) => event.code
    }),
    setConnection: assign({
      error: null,
      email: null,
      phoneNumber: null,
      verificationCode: null,
      connection: (_context, event) => event.connection
    }),
    setRedirectionCookie: () => {
      // this cookie, tells b2c app where to redirect after a successful login
      setCookie({}, "auth0RedirectUrl", window.location.href, {
        domain: ".yamsafer.com"
      });
    },
    setPhoneOrEmail: assign((context, event) =>
      Object.assign(
        {
          error: null
        },
        context.connection === "sms" && {
          phoneNumber: event.phoneNumber
        },
        context.connection === "email" && {
          email: event.email
        }
      )
    )
  }
};

const loginMachine = Machine({
  id: "login",
  context: {},
  initial: "idle",
  on: {
    social: {
      target: ".social",
      actions: ["setRedirectionCookie"]
    },
    passwordless: {
      target: ".passwordless",
      actions: ["setConnection", "setRedirectionCookie"]
    }
  },
  states: {
    idle: {},
    passwordless: {
      initial: "inputEmailOrPhone",
      states: {
        inputEmailOrPhone: {
          on: {
            submit: {
              target: "submittingEmailOrPhone",
              actions: "setPhoneOrEmail"
            }
          }
        },
        submittingEmailOrPhone: {
          invoke: {
            src: "passwordLessStart",
            onDone: "inputCode",
            onError: {
              target: "inputEmailOrPhone",
              actions: "setError"
            }
          }
        },
        inputCode: {
          on: {
            submit: {
              target: "submittingVerificationCode",
              actions: "setCode"
            }
          }
        },
        submittingVerificationCode: {
          invoke: {
            src: "passwordLessLogin",
            onDone: "success",
            onError: {
              target: "inputCode",
              actions: "setError"
            }
          }
        },
        success: {
          type: "final"
        }
      }
    },
    social: {
      actions: "setConnection",
      invoke: {
        src: "socialLogin",
        onError: "failure",
        onDone: "success"
      }
    },
    success: {
      type: "final"
    },
    failure: {
      on: {
        RETRY: {
          target: "idle"
        }
      }
    }
  }
});

export default loginMachine;
