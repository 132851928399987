import { Box, Flex, Text } from "@yamsafer/styleguide";
import { format } from "date-fns";
import React from "react";
import { FormattedMessage } from "react-intl";

type ReservationInfoProps = {
  nights: number;
  checkinData: Date;
  checkoutDate: Date;
  checkInTime: string;
  checkOutTime: string;
};

const ReservationInfo = ({
  nights,
  checkinData,
  checkoutDate,
  checkInTime,
  checkOutTime
}: ReservationInfoProps) => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      {/* Checkin */}
      <Box>
        <Text color="info">
          <FormattedMessage id="checkin" defaultMessage="Checkin" />
        </Text>
        <Text color="primary" fontSize={"1.333333333rem"} my={2}>
          {format(checkinData, "MMM D, YYYY")}
        </Text>
        <Text color="info">
          {format(checkinData, "ddd")}, {checkInTime}
        </Text>
      </Box>
      {/* Nights */}
      <Flex flexDirection="column" alignItems="center">
        <Text fontSize={"1.5rem"}>{nights}</Text>
        <Text color="info">
          <FormattedMessage id="nights" defaultMessage="Nights" />
        </Text>
      </Flex>
      {/* Checkout */}
      <Flex alignItems="flex-end" flexDirection="column">
        <Text color="info">
          <FormattedMessage id="checkout" defaultMessage="Checkout" />
        </Text>
        <Text color="primary" fontSize={"1.333333333rem"} my={2}>
          {format(checkoutDate, "MMM D, YYYY")}
        </Text>
        <Text color="info">
          {format(checkoutDate, "ddd")}, {checkOutTime}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ReservationInfo;
