import { Flex, Heading, Image, Text } from "@yamsafer/styleguide";
import React from "react";
import Stars, { StarsLength } from "./Stars";

type PropertyInfoProps = {
  stars: StarsLength;
  name: string;
  city: string;
  country: string;
  address?: string;
  image: string;
};

const PropertyInfo = ({
  stars,
  name,
  address,
  city,
  country,
  image
}: PropertyInfoProps) => {
  return (
    <Flex alignItems="center" flexDirection="column">
      <Image src={image} alt={name} mb={23} />
      <Stars length={stars} />
      <Heading fontSize={"2rem"} mb={1} textAlign="center">
        {name}
      </Heading>
      <Text color="info" textAlign="center">
        {[address, city, country].filter(Boolean).join(", ")}
      </Text>
    </Flex>
  );
};

export default PropertyInfo;
