import YsStar from "@yamsafer/icons/review/star";
import { Flex, Text } from "@yamsafer/styleguide";
import React from "react";

export type StarsLength = number;

type StarsProps = {
  length: StarsLength;
  size?: number;
};

const Stars = ({ length, size = 16 }: StarsProps) => (
  <Flex alignItems="center" my={2}>
    {Array.from({ length: +length }, (_, key) => (
      <Text key={`star-${key}`} color="rgb(246, 190, 44)" ms={1}>
        <YsStar fill="currentColor" width={size} height={size} key={key} />
      </Text>
    ))}
  </Flex>
);

export default Stars;
