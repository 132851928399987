import React from 'react';
import { Flex, Box, Heading, Button, Text } from '@yamsafer/styleguide';
import { FormattedMessage } from 'react-intl';
import Price from './PriceRenderer';
import PlusCircle from '@yamsafer/icons/search/plusCircle';
import RateDetailsAndPolicy from './RateDetailsAndPolicy';

type RateProps = {
	onViewDetails: (e: Event) => void;
	id: string;
	name: string;
	kids: number;
	adults: number;
	isRefundable: boolean;
	benefits: string[];
	price: number;
};

const Rate = ({
	name,
	adults,
	kids,
	isRefundable,
	benefits = [],
	price,
	onViewDetails
}: RateProps) => (
	<>
		<Flex justifyContent="space-between">
			<Box>
				{/* Room Name */}
				<Heading fontSize="1.166666667rem" fontWeight="bold" mb={1}>
					{name}
				</Heading>

				{/* Is Refundable */}
				<Text color={'primary'} mb={1}>
					<FormattedMessage
						id="refundableStatus"
						values={{ isRefundable }}
						defaultMessage="{isRefundable, select, true {Refundable} other {Non Refundable} }"
					/>
				</Text>

				{/* Benefits */}
				<Text color="success" mb={1}>
					{benefits.join(', ')}
				</Text>

				{/* Occupancy */}
				<Text color={'primary'} mb={1}>
					<FormattedMessage
						id="adultFit"
						values={{ adults, kids }}
						defaultMessage={
							'Fits {adults, plural, =0 {} one {1 Adult} other {{adults} Adults}} {kids, plural, =0 {} one {, 1 Child} other {, {kids} Children}}'
						}
					/>
				</Text>

				{/* Detauls & Policy Button */}
				<Button
					m={0}
					px={0}
					py={0}
					variant="transparent"
					onClick={onViewDetails}
				>
					<Flex alignItems="center">
						<PlusCircle height="10px" /> &nbsp;
						<FormattedMessage
							id="rateDetailsAndPolicyBUtton"
							defaultMessage="Details & Policy"
						/>
					</Flex>
				</Button>
			</Box>

			{/* Price is rendered from the outside, we only set it's position here */}
			<Box>
				<Text color="primary">
					<Price value={price} />
				</Text>
			</Box>
		</Flex>
		<hr />
	</>
);

const Rates = ({ rates }: { rates: RateProps[] }) => (
	<Box>
		{rates.map((rate, index) => (
			<Rate key={index + '_' + rate.id} {...rate} />
		))}
	</Box>
);

export default Rates;
