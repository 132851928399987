// Not included:
// Tourism fee 2.72 USD per room

// These will be charged separately to you by the hotel during your stay.

import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Heading, Text } from "@yamsafer/styleguide";

const Fees = ({ fees }: { fees: string[] }) =>
  fees.length ? (
    <Box>
      <Heading fontSize="1.1667rem" mb={2}>
        <FormattedMessage id="notIncluded" defaultMessage="Not Included " />
      </Heading>
      {fees.map(fee => (
        <Text>{fee}</Text>
      ))}
      <Text>
        <FormattedMessage
          id="notIncludedFeesNote"
          defaultMessage="These will be charged separately to you by the hotel during your stay."
        />
      </Text>
    </Box>
  ) : null;

export default Fees;
