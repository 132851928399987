import auth0 from "auth0-js";
import config from "./config";

const client = new auth0.WebAuth({
  domain: config.AUTH0_DOMAIN,
  clientID: config.AUTH0_CLIENT_ID,
  redirectUri: config.REDIRECT_URI,
  responseMode: "form_post",
  responseType: "token",
  prompt: "none",
  scope: config.AUTH0_SCOPE
});

export default client;
