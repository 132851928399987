import ChevronDown from '@yamsafer/icons/directional/chevronDown';
import ResponsiveDropdown from '@yamsafer/responsive-dropdown';
import { Box, Button, Card, Flex, Image, Text } from '@yamsafer/styleguide';
import { NextPage } from 'next';
import Link from 'next/link';
import { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { FaRegCalendarCheck, FaWallet } from 'react-icons/fa';
import { FiUser, FiPower } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import Container from '../components/Layout/Container';
import Login from '../features/auth';
import useUser, { useWallet } from '../features/auth/useUser';
import HotelsCheckoutSummary from '../hotels-checkout-summary/src';
import Menu, { MenuItem } from '../views/profile/Menu';
import { setCookie } from 'nookies';
import styled from 'styled-components';

const menuOptions = [
	{
		as: 'a',
		props: {
			href: 'https://www.yamsafer.com/en/profile/bookings'
		},
		label: (
			<FormattedMessage id="myBookings" defaultMessage="My Bookings" />
		),
		icon: <FaRegCalendarCheck />
	},
	{
		as: 'a',
		props: {
			href: 'https://www.yamsafer.com/en/profile/edit'
		},
		label: (
			<FormattedMessage id="editProfile" defaultMessage="Edit Profile" />
		),
		icon: <FiUser />
	},
	{
		as: '',
		props: {
			onClick: () => {
				setCookie({}, 'auth0RedirectUrl', window.location.href, {
					domain: '.yamsafer.com'
				});
				window.location.href =
					'https://b2c-auth-cookie-domain-v2.yamsafer.com/logout';
			}
		},
		label: <FormattedMessage id="logOut" defaultMessage="Log out" />,
		icon: <FiPower />
	}
];

const NavButton = styled(Button)`
	color: ${props => props.theme.colors.primary};
	border: 1px solid ${props => props.theme.colors.primary};
`;

interface Props {
	locale: string | string[];
	userAgent?: string;
}

const IMAGE_SIZE = 40;

// replace color with ones from theme
const ProfilePic = styled(Image)`
	object-fit: cover;
	border-radius: 50%;
	width: ${IMAGE_SIZE}px;
	height: ${IMAGE_SIZE}px;
	padding: ${props => (props.isPrime ? '2px' : '')};
	border: ${props => (props.isPrime ? '2px solid #00CFD0' : '')};
`;

const User = ({ picture, name, walletBalance, isPrime }) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<ResponsiveDropdown
			isOpened={isOpen}
			placement={'bottom-end'}
			onRequestClose={e => setIsOpen(false)}
			renderHandle={() => (
				<Flex alignItems="center">
					<ProfilePic src={picture} alt={name} isPrime={isPrime} />
					<Box mx={1}>
						{isPrime && (
							<Text color="muted" fontWeight="bold">
								<FormattedMessage
									id="prime"
									defaultMessage="PRIME"
								/>
							</Text>
						)}
						<Button
							m={0}
							px={0}
							py={0}
							variant="nav"
							color="primary"
							onClick={e => setIsOpen(true)}
						>
							{name} &nbsp;
							<ChevronDown
								height={'0.8em'}
								color="inherit"
								style={{ verticalAlign: 'baseline' }}
							/>
						</Button>
					</Box>
				</Flex>
			)}
		>
			<Box width={246}>
				<Menu options={menuOptions} />
			</Box>
			{walletBalance !== 'undefined' && (
				<>
					<Box as="hr" m={0} />
					<MenuItem
						as="div"
						props={{
							mt: 0
						}}
						label={
							<Flex alignItems="center">
								<Box
									me={1}
									p={'4px'}
									color="white"
									bg="secondary"
									style={{ borderRadius: '50%' }}
								>
									<FaWallet color="inherit" size="16px" />
								</Box>
								<Text color="primary">
									<FormattedMessage
										id="walletBalance"
										defaultMessage="Wallet Balance"
									/>
								</Text>
							</Flex>
						}
						icon={
							<Text
								bg="secondary"
								color="white"
								py={'2px'}
								px={'3px'}
								borderRadius="4px"
							>
								${walletBalance}
							</Text>
						}
					/>
				</>
			)}
		</ResponsiveDropdown>
	);
};

const Navbar = ({ onLoginRequest }) => {
	const { data: user, isLoading } = useUser();
	const { data: wallet } = useWallet();

	return (
		<Container py={'10px'}>
			<Flex alignItems="center" justifyContent="space-between">
				<Box width="100px">
					<Link href={{ pathname: '/' }}>
						<a>
							<Image src={'/logos/logo-en@2x.png'} />
						</a>
					</Link>
				</Box>

				{isLoading && (
					<ContentLoader
						speed={2}
						width={140}
						height={40}
						viewBox="0 0 140 40"
						backgroundColor="#f3f3f3"
						foregroundColor="#ecebeb"
					>
						<rect
							x="48"
							y="8"
							rx="3"
							ry="3"
							width="88"
							height="6"
						/>
						<rect
							x="48"
							y="26"
							rx="3"
							ry="3"
							width="52"
							height="6"
						/>
						<circle cx="20" cy="20" r="20" />
					</ContentLoader>
				)}

				{!isLoading && user && (
					<User
						name={user.name}
						picture={user.picture}
						walletBalance={wallet.active_balance}
						isPrime={user?.user_metadata?.is_subscriber}
					/>
				)}

				{!isLoading && !user && (
					<Box>
						<NavButton variant="nav" onClick={onLoginRequest}>
							<FormattedMessage
								id="login"
								defaultMessage="Login"
							/>
						</NavButton>
					</Box>
				)}
			</Flex>
		</Container>
	);
};

const Page: NextPage<Props> = () => {
	const [isLoginOpen, setIsLoginOpen] = useState(false);

	return (
		<Container>
			<Box mb={5}>
				<Navbar onLoginRequest={() => setIsLoginOpen(true)} />
				<Login
					isLoginOpen={isLoginOpen}
					onRequestLoginClose={() => setIsLoginOpen(false)}
				/>
			</Box>
			<Flex flexDirection={['column', 'row']}>
				<Box width={[1, 16 / 24]}></Box>
				<Card width={[1, 8 / 24]}>
					<HotelsCheckoutSummary />
				</Card>
			</Flex>
		</Container>
	);
};

export default Page;
