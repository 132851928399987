import React from 'react';
import { Card, Box, Flex, Button, Heading, Text } from '@yamsafer/styleguide';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Price from './PriceRenderer';
import BestPriceGuarantee from '@yamsafer/icons/badge/bestPriceGuarantee';
type Tax = {
	code: string;
	name: string;
	amount: number;
	is_included: boolean;
	amount_type: string;
};

type OrderTotalProps = {
	totalWithTax: number;
	orderTotal: number;
	includedTaxes: Tax[];
};

const OrderTotal = ({
	totalWithTax,
	orderTotal,
	includedTaxes = []
}: OrderTotalProps) => {
	return (
		<Box>
			{/* Total Without Tax */}
			<Flex alignItems="center" justifyContent="space-between" my={4}>
				<Heading fontSize="1.51572rem" mb={0}>
					<FormattedHTMLMessage
						id="totalWithoutTaxes"
						defaultMessage="Total <small>(without taxes)</small>"
					/>
				</Heading>
				<Text fontSize="1.5rem" color="primary">
					<Price value={totalWithTax} currencyCode="USD" />
				</Text>
			</Flex>

			{/* Taxes */}

			{includedTaxes
				// sort lines by length, i find it visually nicer
				.sort(
					(prev: Tax, current: Tax) =>
						current.name.length - prev.name.length
				)
				.map((tax: Tax, index) => (
					<Flex
						key={`tax_${index}`}
						alignItems="center"
						justifyContent="space-between"
						ps={2}
						mb={4}
					>
						<Text color="primary" fontSize="1.166666667rem">
							{tax.name}
						</Text>
						<Text color="primary" fontSize="1.166666667rem">
							<Price value={tax.amount} currencyCode="USD" />
						</Text>
					</Flex>
				))}

			{/* Order Total (With Taxes) */}
			<Card bg="rgb(233, 245, 254)" p={'1px'} my={4}>
				<Flex alignItems="center" justifyContent="space-between" p={2}>
					<Heading fontSize="1.51572rem" mb={0}>
						<FormattedHTMLMessage
							id="youWillPay"
							defaultMessage="You will pay"
						/>
					</Heading>
					<Text fontSize="1.5rem" color="primary">
						<Price value={orderTotal} currencyCode="USD" />
					</Text>
				</Flex>
				<Card bg="white" px={2}>
					<Flex justifyContent="space-between" alignItems="center">
						<Flex alignItems="center">
							<BestPriceGuarantee width="20" />
							<Text
								color="primary"
								fontSize={'1.166666667rem'}
								pt={1}
								ms={1}
							>
								<FormattedHTMLMessage
									id="bestPriceGuarantee"
									defaultMessage="Best Price Guarantee"
								/>
							</Text>
						</Flex>
						<Button
							variant="transparent"
							onClick={e => console.log(e)}
						>
							<FormattedHTMLMessage
								id="viewDetails"
								defaultMessage="View Details"
							/>
						</Button>
					</Flex>
				</Card>
			</Card>
		</Box>
	);
};

export default OrderTotal;

// includedTaxes={includedTaxes}
// totalWithoutTax={totalWithoutTax}
// isCashbackEnabled={isCashbackEnabled}
// allTaxesIncluded={allTaxesIncluded}
// totalAfterCashback={totalAfterCashback}
// isPrepaid={isPrepaid}
// maxFracts={2}
