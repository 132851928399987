import { Box } from '@yamsafer/styleguide';
import React, { useState } from 'react';
import data from './data.json';
import PropertyInfo from './PropertyInfo';
import Rates from './Rates';
import ReservationInfo from './ReservationInfo';
import OrderTotal from './OrderTotal';
import Fees from './Fees';
import RateDetailsAndPolicy from './RateDetailsAndPolicy';

export default function() {
	const {
		order: {
			rates,
			property_details,
			reservation_details,
			total_without_tax,
			taxes = [],
			order_total,
			fees
		}
	} = data;

	const { nights, checkin_date, checkout_date } = reservation_details;

	const {
		stars,
		country,
		city,
		address,
		name,
		cover_image,
		check_in,
		check_out
	} = property_details;

	const includedTaxes = taxes.filter(
		tax => tax.is_included && !tax.is_hotel_fee
	);

	const [activeRateId, setActiveRateId] = useState<string | null>(null);
	const activeRate = rates.find(
		({ rate_identifier }) => rate_identifier === activeRateId
	);

	return (
		<>
			<Box mb={5}>
				<PropertyInfo
					city={city}
					name={name}
					stars={+stars}
					country={country}
					address={address}
					image={cover_image}
				/>
				<Box as="hr" />
				<ReservationInfo
					nights={nights}
					checkInTime={check_in}
					checkOutTime={check_out}
					checkinData={new Date(checkin_date)}
					checkoutDate={new Date(checkout_date)}
				/>
				<Box as="hr" />
				<Rates
					rates={rates.map(rate => ({
						kids: +rate.children, // one does not simply send children prop to React ;)
						adults: +rate.adults,
						benefits: rate.benefits,
						id: rate.rate_identifier,
						name: rate.accommodation_name,
						isRefundable: rate.is_refundable,
						price: rate.promotion_rate,
						onViewDetails: () =>
							setActiveRateId(rate.rate_identifier)
					}))}
				/>
				<OrderTotal
					totalWithTax={total_without_tax}
					includedTaxes={includedTaxes}
					orderTotal={order_total}
				/>
				<Fees fees={fees} />
			</Box>
			{activeRate && (
				<RateDetailsAndPolicy
					isOpened={activeRate}
					baseRate={activeRate.base_rate}
					isPrepaid={activeRate.is_prepaid}
					rateBenefits={activeRate.benefits}
					isPromotion={activeRate.is_promotion}
					nightlyRates={activeRate.nightly_rates}
					promotionRate={activeRate.promotion_rate}
					accommodationName={activeRate.accommodation_name}
					cancellationPolicy={activeRate.cancellation_policy}
					onRequestClose={(_e: Event) => setActiveRateId(null)}
					checkinInstructions={activeRate.checkin_instructions}
					extraBedPolicy={property_details.children_extra_beds_policy}
				/>
			)}
		</>
	);
}
