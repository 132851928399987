import { useMachine } from "@xstate/react";
import React from "react";
import loginMachine, { config } from "./machines/login";
import {
  Flex,
  Heading,
  Card,
  Button,
  Absolute,
  Text
} from "@yamsafer/styleguide";
import InitialScreen from "./screens/initial";
import PasswordLessLogin from "./screens/PasswordLessLogin";
import Modal from "react-modal";
import { FormattedMessage } from "react-intl";

const CloseBtn = ({ onClick }) => (
  <Button
    m={0}
    py={"2px"}
    width={36}
    height={36}
    fontSize={"x-large"}
    variant="transparent"
  >
    &times;
  </Button>
);

export const Login = ({ onRequestLoginClose }) => {
  const [state, send, service] = useMachine(loginMachine, config);
  return (
    <Card
      p={5}
      width={400}
      boxShadow="small"
      style={{ minHeight: 360, position: "relative" }}
    >
      <Absolute top={5} end={5} onClick={onRequestLoginClose}>
        <CloseBtn onClick={onRequestLoginClose} />
      </Absolute>

      {state.context.error && (
        <Text color="danger" fontWeight="bold" my={2}>
          {state.context.error.data.description}
        </Text>
      )}

      {state.matches("idle") ? (
        <InitialScreen
          onSocial={connection => send("social", { connection })}
          onPasswordless={connection => send("passwordless", { connection })}
        />
      ) : state.matches("passwordless") ? (
        <PasswordLessLogin
          service={service}
          connection={state.context.connection}
          onSocial={connection => send("social", { connection })}
          onSwitchConnection={({ from, to }) => {
            send("passwordless", { connection: to });
          }}
        />
      ) : state.matches("social") ? (
        <Flex
          height={296}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Heading m={0}>
            <FormattedMessage
              id="beingRedirected"
              defaultMessage="You are being redirectd..."
            />
          </Heading>
        </Flex>
      ) : null}
    </Card>
  );
};

const LoginInModal = ({ isLoginOpen, onRequestLoginClose }) => (
  <Modal
    isOpen={isLoginOpen}
    onRequestClose={onRequestLoginClose}
    style={{
      content: {
        background: "transparent",
        top: "50%",
        left: "50%",
        right: "auto",
        padding: 4,
        bottom: "auto",
        marginRight: "-50%",
        border: "none",
        transform: "translate(-50%, -50%)"
      }
    }}
  >
    <Login />
  </Modal>
);

export default LoginInModal;
