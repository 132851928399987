import FacebookIcon from "@yamsafer/icons/social-media/facebook";
import GoogleIcon from "@yamsafer/icons/social-media/google";
import TwitterIcon from "@yamsafer/icons/social-media/twitter-pure";
import { Box, Button, Flex, Text } from "@yamsafer/styleguide";
import React from "react";
import { FormattedMessage } from "react-intl";

const connectionPropsMap = {
  twitter: {
    icon: TwitterIcon,
    color: "#1da1f2",
    text: (
      <FormattedMessage
        id="loginWithTwitter"
        defaultMessage="Log in with Twitter"
      />
    )
  },
  facebook: {
    icon: FacebookIcon,
    color: "#3b5998",
    text: (
      <FormattedMessage
        id="loginWithFacebook"
        defaultMessage="Log in with Facebook"
      />
    )
  },
  google: {
    icon: GoogleIcon,
    color: "#D34836",
    text: (
      <FormattedMessage
        id="loginWithGoogle"
        defaultMessage="Log in with Google"
      />
    )
  }
};
export const SocialButton = ({ connection, onClick, ...rest }) => {
  const { icon: Icon, color, text } = connectionPropsMap[connection];

  return (
    <Button
      height={40}
      width="100%"
      onClick={e => onClick(connection)}
      style={{ border: "none", background: color }}
      {...rest}
    >
      <Flex height="100%" alignItems="center">
        <Icon height={20} width={20} fill="white" />
        <Box width={"1px"} height={"100%"} bg="white" mx={2} />
        <Text
          as={Flex}
          width="100%"
          fontWeight="400"
          justifyContent="center"
          fontSize="1.333333333rem"
        >
          {text}
        </Text>
      </Flex>
    </Button>
  );
};

const SocialOptions = ({ onClick }) => (
  <>
    <SocialButton connection="google" onClick={onClick} />
    <SocialButton connection="facebook" onClick={onClick} />
    <SocialButton connection="twitter" onClick={onClick} />
  </>
);

export default SocialOptions;
